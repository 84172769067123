.mt15{
    margin-bottom: 15px;
}
.imageUrl{
    width: 150px;
    height: 150px;
    margin-left: 20px;
}
.w150{
    width: 150px!important;
}
.childLabel {
    width: 74.5%!important;
    margin-left: 95px;
    margin-bottom: 20px;
    .ant-select-dropdown {
        top: 40px!important;
    }
}
.inputs2{
    width: 80%!important;
}
.inputs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-right-width: 1px;
        outline: 0;
    }
}
.braftEditor {
    width: 100%;
    height: auto;
    .publishBtn {
        border-color: #60b731;
        background: #60b731;
        color: #fff;
        &:focus {
            color: #60b731;
            border-color: #60b731;
        }
        &:hover {
            color: #fff;
            border-color: #60b731;
        }
    }
    .editors {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #ccc;
        margin-bottom: 15px;
    }
    .items {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .inputboxs{
            width: 100%;
            display: flex;
        }
        .inputbox {
            flex: 1;
            
            .input {
                width: 80%;
            }
            .marks {
                color: #409EFF;
                font-weight: bold;
                padding-left: 20px;
            }
        }
        .label {
            width: 100px;
            text-align: right;
            padding-right: 5px;
        }
    }
    .btnBox {
        padding-left: 100px;
        button {
            margin-right: 15px;
        }
    }
}

.seartchBox{
   /*  display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 30px; */
}
// .ql-container.ql-snow {
//     min-height: 200px;
// }
.aaa{
    min-height: 200px;
}
::v-deep .ql-toolbar.ql-snow{
    min-height: 200px !important;
}