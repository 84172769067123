/* .w140 {
    width: 140px;
} */
.labeSearbar{
    margin-left: -100px!important;
}
.replas {
    position: relative;
}
.childLabel {
    width: 74.5%!important;
    margin-left: 95px;
    margin-bottom: 20px;
}
.paginationsBox {
    text-align: right;
    padding: 15px 0;
}

.resetBtn {
    margin-left: 10px;
}

.poinitBox {
    color: #409EFF;
    padding-right: 10px;
    cursor: pointer;
}
.mainTitle{
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}
.publishBtn {
    border-color: #60b731;
    background: #60b731;
    margin-left: 10px;
    color: #fff;
    &:focus {
        color: #60b731;
        border-color: #60b731;
    }
    &:hover {
        color: #60b731;
        border-color: #60b731;
    }
}