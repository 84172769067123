@import '~antd/dist/antd.css';
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    padding-left: 50px;
}

.ant-form-item {
    padding-right: 5px;
}

.ant-layout-header {
    height: 35px;
    line-height: 35px;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 35px;
    cursor: pointer;
    transition: color 0.3s;
    margin-top: -10px;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    min-height: 32px;
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
}

#root {
    width: 100vw;
    height: 100vh;
}

.wrap {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.ant-layout {
    flex-direction: row;
}

.ant-select-dropdown{
    left: 0 !important;
    top:0 !important;
}

.publish .ant-select-dropdown{
    top:40px !important;
}
.articelss .ant-select-dropdown{
    top:40px !important;
}