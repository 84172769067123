.wrap {
    .imageUrl{
        width: 150px;
        height: 150px;
        margin-left: 20px;
    }
    .w500 {
        width: 500px;
    }
    .w425{
        width: 425px;
    }
    .btnBox {
        padding-left: 70px;
    }
    .ml10 {
        margin-left: 10px;
    }
    .inputs {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
            border-right-width: 1px;
            outline: 0;
        }
    }
}