.contentWrap {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    .headers{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .users{
            padding-right: 30px;
            .logout{
                padding-left: 30px;
                cursor: pointer;
            }
        }
    }
}
