.wrap {
    .publishBtn {
        border-color: #60b731;
        background: #60b731;
        color: #fff;
        margin: 0 10px;
        &:focus {
            color: #60b731;
            border-color: #60b731;
        }
        &:hover {
            color: #fff;
            border-color: #60b731;
        }
    }
    .poinitBox {
        color: #409EFF;
        padding-right: 10px;
        cursor: pointer;
    }
}