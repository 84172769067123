.wrap {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    .logoBig {
        width: 300px;
    }
    .logo {
        width: 200px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .formBox {
        width: 100vw;
        height: 260px;
        background: #405ef9;
        display: flex;
        align-items: center;
        justify-content: center;
        /*  text-align: center; */
        .formItem {
            width: 300px;
            height: 260px;
            margin-left: 50px;
        }
    }
}