.wrap {
    .w500 {
        width: 500px;
    }
    .btnBox {
        padding-left: 70px;
    }
    .ml10 {
        margin-left: 10px;
    }
}