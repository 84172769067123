.wrap {
    .mr10 {
        margin-right: 10px;
    }
    .picbox {
        width: 200px;
        height: 120px;
        background-color: #C0C4CC;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .poinitBox {
        color: #409EFF;
        padding-right: 10px;
        cursor: pointer;
    }
    .publishBtn {
        border-color: #60b731;
        background: #60b731;
        color: #fff;
        margin: 0 10px;
        &:focus {
            color: #60b731;
            border-color: #60b731;
        }
        &:hover {
            color: #fff;
            border-color: #60b731;
        }
    }
}