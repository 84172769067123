.wrap {
    .header {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
    }
    .locaBox {
        display: flex;
        align-items: center;
    }
    .textArea {
        width: 300px;
        height: 105px;
        margin-top: 20px;
    }
    .labelTitle {
        margin-right: 70px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-weight: bold;
        color: #303133;
        background-color: #C0C4CC;
    }
    .publishBtn {
        border-color: #60b731;
        background: #60b731;
        color: #fff;
        margin: 0 10px;
        &:focus {
            color: #60b731;
            border-color: #60b731;
        }
        &:hover {
            color: #fff;
            border-color: #60b731;
        }
    }
}