.noAuth{
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    padding-top: 50px;
    .login{
        font-size: 16px;
        color: #5468ff;
        cursor: pointer;
        text-decoration: underline;
    }
}