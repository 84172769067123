.wrap {
    .header {
        display: flex;
        justify-content: space-between;
    }
    .pl100 {
        padding-left: 100px;
    }
    .sortIcon {
        font-size: 18px;
        cursor: pointer;
    }
    .ml10 {
        margin-left: 10px;
    }
    .list {
        list-style: none;
        padding: 0;
        margin: 0;
        .titleBar {
            display: flex;
            justify-content: space-between;
            .sortBox {
                padding-right: 100px;
            }
        }
        .itemContents {
            flex: 1;
        }
        .itemPic {
            width: 240px;
            height: 160px;
            background: #ccc;
            margin-right: 20px;
        }
        .infos {
            width: 100%;
            height: 110px;
        }
        .itemBox {
            display: flex;
        }
        .item {
            border-bottom: 1px solid #ccc;
            padding-top: 20px;
            padding-bottom: 20px;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}